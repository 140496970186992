import Portal from '@mui/base/Portal';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ComponentType,
  type KeyboardEventHandler
} from 'react';

import {
  AxDialogHostPresenter,
  AxDialogVariantLayout,
  type AX_DIALOG_VARIANTS,
  type AxDialogHostPresenterOptions,
  type AxDialogVariantLayoutProps
} from '@common/modules/react/themes/components';
import {
  type SpacingFactorNumber,
  type SpacingUiKitSizeString
} from '@common/modules/react/themes/ThemeSpacing';

import useAndroidBackHandler from '@common/libs/behaviors/androidBackHandler/useAndroidBackHandler';
import trapTabKey from '@common/libs/behaviors/tabTrapper/TabTrapperHelpers';
import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import { type PaperProps as MuiPaperProps } from '@mui/material';
import { merge } from 'lodash';

// These are repeated here from globals.d.ts otherwise storybook doesn't generate the controls tabled properly.
type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;
type Override<T extends IndexableObject<object>, R extends IndexableObject<object>> = DistributiveOmit<T, Extract<keyof R, keyof T>> & R;

type AxDialogLayoutVariantProps = DistributiveOmit<AxDialogVariantLayoutProps, 'variant' | 'qaClassName'>;

export type AxDialogProps<
  LayoutProps extends IndexableObject<object> = AxDialogLayoutVariantProps
> = Override<AxDialogHostPresenterOptions, {
  /**
  *  [Advanced usage only] The content of the component. Should rely on `variant` and `layoutProps` instead, unless a very specific use case.
  **/
  children?: React.ReactNode
  /**
  *  If `false`, there is no header close button and clicking the backdrop will do nothing. Extra buttons or mechanisms need to be provided to explicitly close the modal (ie. action button or in reaction to a network request success).
  *  @default true
  **/
  closable?: boolean
  /**
  *  If `true`, clicking the backdrop will close the component.
  *  @default true
  **/
  enableBackdropClose?: boolean
  /**
  *  If `true`, take up as much vertical space as the container allows, even if the content doesn't fill it. Useful when extra content will be loaded that could cause modal height to jump.
  *  @default false
  **/
  fullHeight?: boolean
  /**
  *  If `true`, the modal takes up all available width and height.
  *  @default false
  **/
  fullScreen?: boolean
  /**
  *  If `true`, take up as much horizontal space as the container allows, even if the content doesn't fill it.
  *  @default false
  **/
  fullWidth?: boolean
  /**
  *  Available Layout component override. Defaults to `AxDialogVariantLayout`.
  **/
  LayoutComponent?: ComponentType<DistributiveOmit<AxDialogVariantLayoutProps, 'variant'>>
  /**
  *  Available properties for the applicable variant layout (ie. `AxDialogDefaultLayoutProps` or `AxDialogConfirmationLayoutProps`).
  **/
  layoutProps?: LayoutProps
  /**
  *  Sets the maximum width the modal is allowed to grow to based on it's contents.
  **/
  maxWidth?: `xs` | `sm` | `md` | `lg` | `xl`
  /**
  *  Callback fired when Android back button used (ensure dialog closes itself).
  **/
  onAndroidBack?: () => boolean
  /**
  *  Callback fired when the component requests to be closed.
  **/
  onClose?: () => void
  /**
  *  Callback fired when the component requests to navigate back.
  **/
  onBack?: () => void
  /**
  *  If `true`, the component is shown.
  *  @default true
  **/
  open: boolean
  /**
  *  Define minimum gutter between modal and container using `theme.spacing` values.
  *  @default l
  **/
  outsideSpacing?: SpacingUiKitSizeString | SpacingFactorNumber
  /**
  *  Props applied to the `Paper` element.
  **/
  PaperProps?: MuiPaperProps
  /**
  *  If `true`, pushes modal to appear first in the DOM if there are multiple modals present.
  *  @default false
  **/
  push?: boolean
  /**
  *  Restrain keyboard navigation tab key within the modal.
  *  @default true
  **/
  trapTabs?: boolean
  /**
  *  The variant to use.
  *  @default 'default'
  **/
  variant?: AX_DIALOG_VARIANTS
  /**
  *  Position of modal in relation to page.
  *  @default top
  **/
  verticalPosition?: `top` | `center` | `bottom`
  /**
   * Text align the content text of the modal.
   * @default start - only applies to default variant
   * @default center - only applies to confirmation variant
   */
  alignContentText?: AxDialogHostPresenterOptions['alignContentText']
  /**
   * A class name used for quality assurance testing that can be applied automatically to the appropriate sub component that needs it.
   */
  qaClassName: `qa-${ string }-dialog`
}>;

/**
 * Used to perform a specific simple or complex action, but keep users on the same page once that action is completed.  They help direct the users attention to the action or information.
 *
 * ### Links
 * - • [MUI | Dialog](https://mui.com/material-ui/react-dialog/)
 * - • [MUI | Dialog API](https://mui.com/material-ui/api/dialog/)
 * - • [Mockups](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=16113-8117&t=0unlsHoGH5KE6369-4)
 */
export default function AxDialog<LayoutProps extends IndexableObject<object> = AxDialogLayoutVariantProps>(props: AxDialogProps<LayoutProps>): JSX.Element {
  const {
    children,
    variant = 'default',
    closable = true,
    open = true,
    onClose,
    onBack,
    onAndroidBack = () => {
      return false;
    },
    PaperProps = {},
    enableBackdropClose,
    trapTabs = true,
    layoutProps = {},
    LayoutComponent = AxDialogVariantLayout,
    qaClassName,
    className,
    ...dialogProps
  } = props;

  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const onDefaultClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onCalculatedClose = closable ? (onClose ?? onDefaultClose) : undefined;

  const onDefaultAndroidBack = useCallback((): boolean => {
    onCalculatedClose?.();

    return isOpen;
  }, [isOpen, onCalculatedClose]);

  const onAndroidBackHandler = useCallback(() => {
    if (!isOpen) {
      return false;
    }
    return onAndroidBack() || onDefaultAndroidBack();
  }, [onAndroidBack, onDefaultAndroidBack, isOpen]);

  useAndroidBackHandler(onAndroidBackHandler);

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>((e) => {
    if (trapTabs) {
      trapTabKey(e, container);
    }

    if (!e.isDefaultPrevented()) {
      PaperProps.onKeyDown?.(e);
    }
  }, [PaperProps, container, trapTabs]);

  const internalPaperProps = useMemo(() => {
    return {
      ref: setContainer,
      onKeyDown
    };
  }, [onKeyDown]);

  if (!isOpen) {
    return <></>;
  }

  const variantLayoutProps = {
    variant,
    onClose: onCalculatedClose,
    onBack,
    qaClassName: qaClassName as `qa-${ string }`,
    ...layoutProps
  };

  const portalContent = children ?? <LayoutComponent { ...variantLayoutProps } />;

  const portal = container && <Portal container={ container }>{ portalContent }</Portal>;

  const presenterProps = {
    variant,
    open: isOpen,
    onClose: onCalculatedClose,
    enableBackdropClose,
    PaperProps: merge({}, PaperProps, internalPaperProps),
    className: joinClassNames(qaClassName, className),
    ...dialogProps
  };

  return <>
    <AxDialogHostPresenter { ...presenterProps } />
    { portal }
  </>;
}

