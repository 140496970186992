import {
  AxIcon,
  AxIconButton
} from '@common/modules/react/themes/components';

import I18n from '@common/libs/I18n';

type AxDialogCloseButtonProps = {
  qaClassName: `qa-${ string }`
  onClose?: () => void
};

export default function AxDialogCloseButton(props: AxDialogCloseButtonProps) {
  const {
    onClose,
    qaClassName
  } = props;

  return <AxIconButton
    className={ qaClassName }
    size='medium'
    aria-label={ I18n.t('general.close') }
    onClick={ onClose }
    sx={{
      padding: 0,
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent'
      }
    }}
  >
    <AxIcon iconName='icon-remove' />
  </AxIconButton>;
}
